<template>
  <v-container fluid>
    <v-row v-if="loader">
      <v-col class="d-flex flex-row align-center justify-space-around">
        <v-progress-circular indeterminate color="success"/>
      </v-col>
    </v-row>
    <v-row v-if="!loader">
      <v-col>
        <span>
          <span class="d-flex flex-row align-center hideOnPagePrint">
            <v-btn text @click="$router.go(-1)"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <h1>Viewing Register</h1>
            <v-btn v-if="register && Object.keys(register).length>0" @click="printData" class="ml-2" fab color="info" x-small><v-icon>mdi-printer</v-icon></v-btn>
            <v-btn class="ml-2" small color="success" v-if="register && Object.keys(register).length>0 && !register.closedAt && isAllowed('register', 'canCloseAny')" @click="$router.push(`/registers/close/${register.uuid}`)">Close Register</v-btn>
          </span>
          <h2 v-if="register && Object.keys(register).length>0">Register: {{register.uuid}}</h2>
          <span>
            <span class="d-flex flex-column">
              <span>Opening Time: {{utils.formatDate(register.openedAt, 'withTime')}} by {{lookupUsername(register.openedBy)}}</span>
              <span>Opening Notes:
                <pre class="pl-5">{{register.openingNotes}}</pre>
              </span>
              <br v-if="register.closedAt">
              <span v-if="register.closedAt">Closing Time: {{utils.formatDate(register.closedAt, 'withTime')}} by {{lookupUsername(register.closedBy)}}</span>
              <span v-if="register.closedAt">Closing Notes:
                <pre class="pl-5">{{register.closingNotes}}</pre>
              </span>
            </span>
          </span>
        </span>
      </v-col>
    </v-row>
    <v-row v-if="!loader">
      <v-col>
        <h2>Summary</h2>
        <table class="text-center" style="width: 100%;">
          <thead style="font-size: 18px;">
            <tr>
              <th class="text-left">Type</th>
              <th class="text-right">Expected</th>
              <th class="" style="">Counted</th>
              <th class="text-right">Differences</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(pt, i) of data.payments">
              <tr class="level1Row" v-if="pt.paymentTypeId!=getGlobalValue('cashPayment')" :key="i">
                <td style="font-size: 16px;" class="text-left">{{pt.PaymentType.name}}</td>
                <td class="text-right">{{utils.formatCurrency(pt.metadata.expected)}}</td>
                <td class="px-5">{{utils.formatCurrency(pt.amount)}}</td>
                <td class="text-right">{{utils.formatCurrency(pt.metadata.expected-pt.amount)}}</td>
              </tr>
              <template v-else>
                <tr class="level1Row cashRow" style="font-weight: bold;" :key="i">
                  <td style="font-size: 16px;" class="text-left">{{pt.PaymentType.name}}</td>
                  <td class="text-right">{{utils.formatCurrency(pt.metadata.expected)}}</td>
                  <td class="px-5">
                    <span>{{utils.formatCurrency(pt.amount)}}</span>
                    <span v-if="false" class="d-flex flex-column">
                      <span class="text-left pl-8">
                        <span>{{pt.metadata.amountBreakdown.x100}}</span>
                        <span style="width: 100px;" class="pl-1 text-left">x $100</span>
                      </span>
                      <span class="text-left pl-8">
                        <span>{{pt.metadata.amountBreakdown.x50}}</span>
                        <span style="width: 100px;" class="pl-1 text-left">x $50</span>
                      </span>
                      <span class="text-left pl-8">
                        <span>{{pt.metadata.amountBreakdown.x20}}</span>
                        <span style="width: 100px;" class="pl-1 text-left">x $20</span>
                      </span>
                      <span class="text-left pl-8">
                        <span>{{pt.metadata.amountBreakdown.x10}}</span>
                        <span style="width: 100px;" class="pl-1 text-left">x $10</span>
                      </span>
                      <span class="text-left pl-8">
                        <span>{{pt.metadata.amountBreakdown.x5}}</span>
                        <span style="width: 100px;" class="pl-1 text-left">x $5</span>
                      </span>
                      <span class="text-left pl-8">
                        <span>{{pt.metadata.amountBreakdown.x1}}</span>
                        <span style="width: 100px;" class="pl-1 text-left">x $1</span>
                      </span>
                      <span class="text-left pl-8">
                        <span>{{pt.metadata.amountBreakdown.x025}}</span>
                        <span style="width: 100px;" class="pl-1 text-left">x 25¢</span>
                      </span>
                      <span class="text-left pl-8">
                        <span>{{pt.metadata.amountBreakdown.x010}}</span>
                        <span style="width: 100px;" class="pl-1 text-left">x 10¢</span>
                      </span>
                      <span class="text-left pl-8">
                        <span>{{pt.metadata.amountBreakdown.x05}}</span>
                        <span style="width: 100px;" class="pl-1 text-left">x 5¢</span>
                      </span>
                      <span class="text-left pl-8">
                        <span>{{pt.metadata.amountBreakdown.x01}}</span>
                        <span style="width: 100px;" class="pl-1 text-left">x 1¢</span>
                      </span>
                    </span>
                  </td>
                  <td class="text-right">{{utils.formatCurrency(pt.metadata.expected-pt.amount)}}</td>
                </tr>
                <tr class="pl-5 level1Row cashRow" :key="i+'2nd'">
                  <td>
                    <span class="d-flex flex-row justify-space-between">
                      <span class="pl-5 text-left cashMoveFirstPrint">Cash Movements</span>
                      <span class="text-center cashMoveSecondPrint"><b>User</b></span>
                      <span style="width: 33%;" class="text-left"><b>Time</b></span>
                    </span>
                  </td>
                  <td class="text-right"><b>Amount</b></td>
                  <td colspan="2" class="text-right"><b>Reason</b></td>
                </tr>
                <template v-for="(trans, ti) in cashMovementTransactions">
                  <tr class="level2Row cashRow" :key="i+'2nd'+ti">
                    <td>
                    <span class="d-flex flex-row justify-space-between">
                      <span class="text-right cashMoveFirstPrint"></span>
                      <span class="text-center cashMoveSecondPrint">{{lookupUsername(trans.receivedBy)}}</span>
                      <span style="width: 33%;" class="text-left">{{`${utils.formatDate(trans.createdAt)} ${utils.formatTime(trans.createdAt)}`}}</span>
                    </span>
                    </td>
                    <td :class="`${trans.transactionType===5?'red--text':''} ${trans.metadata?.type==='Opening Float'?'blue&#45;&#45;text':''} text-right pr-1`">{{utils.formatCurrency(trans.amount)}}</td>
                    <td colspan="2" class="text-right pr-1">{{trans.metadata?.type==='Opening Float'?'Opening Float':(trans.notes||'None')}}</td>
                  </tr>
                </template>
                <tr class="level1Row cashRow" :key="i+'3rd'">
                  <td class="pl-5 text-left">Cash Payments From Orders</td>
                  <td class="text-right">{{utils.formatCurrency(pt.metadata.cashReceived)}}</td>
                  <td/>
                  <td/>
                </tr>
                <tr class="level1Row cashRow" :key="i+'4th'">
                  <td class="pl-5 text-left">Cash Redeemed</td>
                  <td class="text-right">{{utils.formatCurrency(pt.metadata.cashRedeemed)}}</td>
                  <td/>
                  <td/>
                </tr>
              </template>
            </template>
          </tbody>
        </table>
      </v-col>
    </v-row>
    <v-row v-if="register.Transactions && register.Transactions.length>0">
      <v-col>
        <h2>All Transactions</h2>
        <table style="width: 100%;">
          <thead>
          <tr>
            <th class="text-left">Doc</th>
            <th class="text-left">Date</th>
            <th class="text-left">User</th>
            <th class="text-left">Type</th>
            <th class="text-right">Amt</th>
            <th class="text-right">P.I.T. Total</th>
          </tr>
          </thead>
          <tbody>
          <tr :class="trans.voidedAt?'strikeout':''" v-for="(trans, k) of register.Transactions" :key="k" @click="(trans.orderId?$router.push(`/orders/view/${trans.orderId}`):null)">
            <td :style="`font-size: ${getGlobalValue('reportFontSize')?getGlobalValue('reportFontSize'):'16px'};`">
              <span v-if="trans.orderId && trans.transactionType===2">INV #{{trans.orderId}}</span>
              <span v-else-if="trans.transactionType===3">CN {{trans.CreditNotes.length>0?`#${trans.CreditNotes[0].id}`:''}}</span>
              <span v-else-if="trans.metadata.type">{{trans.metadata.type}}</span>
              <span v-else-if="trans.transactionType===4">Cash In</span>
              <span v-else-if="trans.transactionType===5">Cash Out</span>
            </td>
            <td :style="`font-size: ${getGlobalValue('reportFontSize')?getGlobalValue('reportFontSize'):'16px'};`">{{utils.formatDate(trans.createdAt)}} {{utils.formatTime(trans.createdAt)}}</td>
            <td :style="`font-size: ${getGlobalValue('reportFontSize')?getGlobalValue('reportFontSize'):'16px'};`">{{lookupUsername(trans.receivedBy)}}</td>
            <td :style="`font-size: ${getGlobalValue('reportFontSize')?getGlobalValue('reportFontSize'):'16px'};`">
              <span v-if="trans.transactionType===2 || trans.transactionType===4 || trans.transactionType===5">{{paymentTypes.find(x => x.id===trans.paymentType)?paymentTypes.find(x => x.id===trans.paymentType).name:'ERROR'}}</span>
              <span v-if="trans.transactionType===3">Redeemed CN as {{paymentTypes.find(x => x.id===trans.paymentType)?paymentTypes.find(x => x.id===trans.paymentType).name:'ERROR'}}</span>
            </td>
            <td :style="`font-size: ${getGlobalValue('reportFontSize')?getGlobalValue('reportFontSize'):'16px'};`" class="text-right">{{utils.formatCurrency(trans.amount)}}</td>
            <td :style="`font-size: ${getGlobalValue('reportFontSize')?getGlobalValue('reportFontSize'):'16px'};`" class="text-right">{{utils.formatCurrency(trans.pitTotal)}}</td>
          </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<style>
table{
  border-collapse: collapse;
}
.level1Row{
  border-top: 1px solid #ddd;
}
.level1Row>td{
  padding: 5px 0 5px 0;
  vertical-align: top;
}
.level2Row{
  border-top: 1px solid #ddd;
}
.cashRow {
  background: white;
}
.level2Row .cashRow td{
  padding: 0px;
}
table th, .level1Row>td{
  padding-left: 5px;
  padding-right: 5px;
}
.cashMoveFirstPrint{
  width: 33%;
}
.cashMoveSecondPrint{
  width: 33%;
}
@media print {
  .cashMoveFirstPrint{
    width: 10%;
  }
  .cashMoveSecondPrint{
    width: 56%;
  }
}
</style>
<script>
import { mapGetters } from 'vuex'
import axios from "axios";
import utils from "../../plugins/helpers"

export default {
  name: 'Home',
  data () {
    return {
      utils: utils,

      snackObj: {
        state: false,
        color: '',
        text: ''
      },

      loader: false,

      data: {
        closingFloat: 0,
        closingNotes: '',
        payments: []
      },

      register: {},
      cashMovementTransactions: [],

      showBreakdown: true,

      closeRegisterDialog: {
        isOpen: false,
        isLoading: false
      }
    }
  },
  computed: {
    ...mapGetters(['getEndpoint', 'getBranch', 'getCurrentRegister', 'lookupUsername', 'getGlobalValue', 'paymentTypes', 'isAllowed'])
  },
  async mounted(){
    try {
      this.loader = true;

      let res = await axios.get(`${this.getEndpoint}/api/registers/sessionWithTransactions/${this.$route.params.id}`)
      if(res.data.error) throw res.data.error
      this.register = res.data.data

      console.log(this.register)

      this.cashMovementTransactions = this.register?.Transactions?.filter(x => x.transactionType===4 || x.transactionType===5)
      this.data.payments = this.register.formattedOutput||[];
    } catch (error) {
      console.error(error)
      this.snack(error.msg || error.msg?.message || error, "error");
    }finally {
      this.loader = false;
    }
  },
  methods: {
    computeBreakdownTotal(){
      let total = 0;

      let found = this.data.payments.find(x => x.paymentTypeId==this.getGlobalValue('cashPayment'));
      if(found){
        total+=parseInt(found.metadata.amountBreakdown.x100||0)*100;
        total+=parseInt(found.metadata.amountBreakdown.x50||0)*50;
        total+=parseInt(found.metadata.amountBreakdown.x20||0)*20;
        total+=parseInt(found.metadata.amountBreakdown.x10||0)*10;
        total+=parseInt(found.metadata.amountBreakdown.x5||0)*5;
        total+=parseInt(found.metadata.amountBreakdown.x1||0)*1;
        total+=parseInt(found.metadata.amountBreakdown.x025||0)*0.25;
        total+=parseInt(found.metadata.amountBreakdown.x010||0)*0.10;
        total+=parseInt(found.metadata.amountBreakdown.x05||0)*0.05;
        total+=parseInt(found.metadata.amountBreakdown.x01||0)*0.01;
      }

      found.amount = utils.pff2(total);
    },
    toggleBreakdownView(){
      // let found = this.data.payments.find(x => x.paymentTypeId==this.getGlobalValue('cashPayment'));
      // if(!found) return;
      // for(let key in Object.keys(found.metadata.amountBreakdown)){
      //   found.metadata.amountBreakdown[key] = 0;
      // }
      // found.amount = 0;
      this.showBreakdown = !this.showBreakdown;
    },
    snack(text, color=""){
      this.snackObj.text = text;
      this.snackObj.state = true;
      this.snackObj.color = color;
    },
    async printData(){
      let els = document.querySelectorAll(".hideOnPagePrint");
      let defaults = [];
      for(let el of els){
        defaults.push(el.style.display);
        el.style.setProperty("display", "none", "important");
      }

      let els2 = document.querySelectorAll(".adjustForPagePrint");
      let defaults2 = [];
      // let defaults3 = [];
      for(let el of els2){
        defaults2.push(el.style.padding);
        // defaults3.push(el.style.paddingRight);
        el.style.setProperty("padding", "0px 50px 0px 0px");
        // el.style.setProperty("paddingRight", "20px");
      }

      window.print();

      for(let i=0;i<els.length;i++){
        els[i].style.setProperty("display", defaults[i], "");
      }

      for(let i=0;i<els2.length;i++){
        els2[i].style.setProperty("padding", defaults2[i], "");
      }
    },
  }
}
</script>
